// This import loads the firebase namespace.
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/database';

// Initialize Firebase
const firebaseConfig = {
    apiKey: 'AIzaSyCG7lEOMNR0Ot2yNPvTZjo5-K_fAOx-cz4',
    authDomain: 'sleepscore-csp.firebaseapp.com',
    databaseURL: 'https://sleepscore-csp-default-rtdb.firebaseio.com',
    projectId: 'sleepscore-csp',
    storageBucket: 'sleepscore-csp.appspot.com',
    messagingSenderId: '22483642501',
    appId: '1:22483642501:web:560f6af925d64b745d61ec',
    measurementId: 'G-757GBJQYCZ'
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const functions = firebase.functions();

export { auth, functions };
