import * as api from '../api';

import React, { useEffect } from 'react';
import { logIn, logOut, getUserId } from '../api';

import { auth } from './../api/firebase';

const ProfileContext = React.createContext({});

/**
 * useProfile - react hook for loading current user profile, making changes
 * @date 2020-03-05
 */

function useProfile() {
    const context = React.useContext(ProfileContext);
    if (context === undefined) {
        throw new Error(`useProfile must be used within a ProfileProvider`);
    }
    return context;
}

const ProfileProvider = props => {
    const [needsLogin, setNeedsLogin] = React.useState(false);
    const [isSu, setIsSu] = React.useState(false);

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            console.log('[profile_provider] auth state changed for user: ', user);
            refreshNeedsLogin();
        });
    }, []);

    const refreshNeedsLogin = () => {
        const authd = api.isLoggedIn() && auth && auth.currentUser && auth.currentUser.uid;
        setNeedsLogin(!authd);
        setIsSu(getIsSu());
        console.log('[profile_provider] refreshed needs_login: ', !authd);
    };

    const getIsSu = () => {
        const suids = ['np6GrYob2od9VKci9NudC0AXFD72', 'xi0naUXc3USIyyaN0QYJ1fey6J82'];
        return suids.includes(getUserId());
    };

    return (
        <ProfileContext.Provider
            value={{
                needsLogin,
                refreshNeedsLogin,
                logIn,
                logOut,
                isSu
            }}
            {...props}
        />
    );
};

export { ProfileProvider, useProfile };
