import 'react-dropdown/style.css';

import * as React from 'react';

import { Button, CircularProgress, Grid, Snackbar } from '@material-ui/core';
import { useEffect, useState } from 'react';

// import { CSVLink } from 'react-csv';
import { DataGrid } from '@material-ui/data-grid';
import Dropdown from 'react-dropdown';
// import GetAppIcon from '@material-ui/icons/GetApp';
// import { GetActivities } from '../api';
import useSWR from 'swr';

const surveyTypes = [
    { value: 'surveys', label: 'Full Score Data' },
    { value: 'scoreless', label: 'Responses Only' }
];

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 200,
        headerClassName: 'grid-header',
        type: 'string'
    },
    {
        field: 'STATUS',
        headerName: 'STATUS',
        width: 150,
        headerClassName: 'grid-header',
        valueFormatter: v => (v.value || '').toUpperCase(),
        type: 'string'
    },
    {
        field: 'CREATED_TIME',
        headerName: 'STARTED',
        width: 200,
        valueFormatter: v => new Date(v.value * 1000).toLocaleDateString(),
        headerClassName: 'grid-header'
    },
    {
        field: 'CSV_URL',
        headerName: 'DOWNLOAD',
        width: 250,
        headerClassName: 'grid-header',
        renderCell: params =>
            !params.value ? (
                <div />
            ) : (
                <a color="primary" href={params.value}>
                    CSV
                </a>
            )
    },
    {
        field: 'TYPE',
        headerName: 'TYPE',
        width: 250,
        headerClassName: 'grid-header',
        valueFormatter: v => (v.value === 'scoreless' ? 'Responses' : 'Full')
    }
];

export default function ReportsPage() {
    const [reportInProgress, setReportInProgress] = useState(false);
    const { data, error, mutate } = useSWR('reports');

    const [createReportType, setCreateReportType] = useState();
    const [createReportFilter, setCreateReportFilter] = useState();
    const [shouldCreateReport, setShouldCreateReport] = useState(false);

    const [noConstCond] = useState(true);

    const { createReportError } = useSWR(
        createReportType && shouldCreateReport && noConstCond
            ? `createReport/type/${createReportType}${
                  createReportFilter
                      ? `/${Object.keys(createReportFilter)[0]}/${
                            createReportFilter[Object.keys(createReportFilter)[0]]
                        }`
                      : ''
              }`
            : null
    );

    useEffect(() => {
        if (error) {
            console.log('[activity] request error: ', error);
        }
    }, [error]);

    // reports each have their own filter dropdowns
    const onSurveyDropdownSelect = v => {
        setCreateReportType('surveys');
        setCreateReportFilter({ ...(v.value == null ? {} : { survey_id: v.value }) });
    };

    const createReport = type => {
        if (shouldCreateReport) {
            return;
        }
        setReportInProgress(true);
        setCreateReportType(type);
        setTimeout(() => {
            console.log('TYPE', createReportType, createReportFilter);
            setShouldCreateReport(true);
        }, 100);
        setTimeout(() => {
            setShouldCreateReport(false);
            setReportInProgress(false);
            mutate();
        }, 1500);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 0
            }}
        >
            <Grid container flexDirection="column" xs={12}>
                <Grid item xs={8}>
                    <h2>REPORTS</h2>
                    <p style={{ textAlign: 'left', marginBottom: '3em' }}>
                        Once you select a report to generate, it may take up to 10 minutes to create
                        the report. A button to download the CSV will appear when ready. You may
                        leave this page while the report generates, and return later to download it.
                    </p>
                </Grid>
            </Grid>
            <Grid
                style={{
                    backgroundColor: '#EEE',
                    borderRadius: '6pt',
                    padding: 20,
                    flexDirection: 'column',
                    display: 'flex',
                    marginBottom: 30
                }}
                xs={8}
                m={2}
                p={2}
            >
                <Grid
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        display: 'flex'
                    }}
                >
                    <h3 style={{ width: '200pt', textAlign: 'left' }}>Survey Report</h3>
                    <Dropdown
                        options={surveyTypes}
                        onChange={onSurveyDropdownSelect}
                        placeholder="Full Score Data"
                        style={{ width: 50 }}
                    />
                    <Button
                        style={{
                            backgroundColor: reportInProgress ? 'grey' : '#4D6078',
                            color: 'white',
                            marginLeft: 30,
                            paddingLeft: 30,
                            paddingRight: 30
                        }}
                        disabled={reportInProgress}
                        onClick={() => createReport('surveys')}
                    >
                        Generate
                    </Button>
                </Grid>
            </Grid>
            <Snackbar
                open={error || createReportError}
                autoHideDuration={8000}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                style={{
                    backgroundColor: 'red',
                    width: '100%'
                }}
            >
                <h4>Error: {error || createReportError}</h4>
            </Snackbar>
            {!data && !error && <CircularProgress style={{ marginBottom: 30 }} />}

            {(reportInProgress || shouldCreateReport) && (
                <CircularProgress style={{ marginBottom: 30 }} />
            )}
            {data?.results && (
                <div style={{ height: '60vh' }}>
                    <DataGrid rows={data.results} columns={columns} pageSize={50} />
                </div>
            )}
        </div>
    );
}
