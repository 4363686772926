import './App.css';

import { Redirect, Route, BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import AppNavigation from './components/AppNavigation';
import { Button } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { Helmet } from 'react-helmet';
import LoginPage from './pages/LoginPage';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SWRConfig } from 'swr';
import { apiClient } from './api';
import { useEffect } from 'react';
import { useProfile } from './providers/ProfileProvider';

const defaultMaterialTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#4D6078'
            }
        }
    }
});

// const theme = createMuiTheme({});
function App() {
    const { needsLogin } = useProfile();

    useEffect(() => {
        console.log('[app] needs login changed: ', needsLogin);
    }, [needsLogin]);

    return (
        <div className="App">
            <Helmet>
                <meta charSet="utf-8" />
                <title>CSP Dashboard</title>
            </Helmet>
            <SWRConfig
                value={{
                    refreshInterval: 0,
                    fetcher: apiClient,
                    shouldRetryOnError: false
                }}
            >
                {!needsLogin && (
                    <header className="App-header">
                        <Button>CSP Dashboard</Button>
                    </header>
                )}
                <ThemeProvider theme={defaultMaterialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Router>
                            {needsLogin && [
                                <Redirect to="/login" />,
                                <Route path="/login">
                                    <LoginPage />
                                </Route>
                            ]}
                            {!needsLogin && (
                                <Route path="/">
                                    <AppNavigation />
                                </Route>
                            )}
                        </Router>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </SWRConfig>
        </div>
    );
}

export default App;
