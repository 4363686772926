import * as React from 'react';

import { CircularProgress, Snackbar } from '@material-ui/core';
import { useEffect, useState } from 'react';

import { DataGrid } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import useSWR from 'swr';

const columns = [
    {
        field: 'EMAIL',
        headerName: 'Email',
        width: 300,
        headerClassName: 'grid-header',
        renderCell: params => (
            <h4 style={{ color: '#4D6078' }}>{(String(params.value) || '').toLowerCase()}</h4>
        )
    },
    {
        field: 'NAME',
        headerName: 'Child Name',
        width: 300,
        headerClassName: 'grid-header',
        type: 'string'
    },
    {
        field: 'id',
        headerName: 'Identifier',
        width: 200,
        headerClassName: 'grid-header',
        type: 'string'
    }
];

export default function ResponsesPage() {
    console.log('[ResponsesPage] init');
    const { data, error } = useSWR('survey');

    const [rows, setRows] = useState([]);

    const [searchTerm, setSearchTerm] = useState();

    useEffect(() => {
        if (data && data.results) {
            const testuser = user =>
                (user &&
                    user.invite_code &&
                    user.invite_code.indexOf &&
                    user.invite_code.indexOf(searchTerm) > -1) ||
                (user &&
                    user.subject_id &&
                    user.subject_id.indexOf &&
                    user.subject_id.indexOf(searchTerm) > -1);

            setRows(
                data.results
                    .map(record => ({ ...(record || {}) }))
                    .filter(d => ((searchTerm || '').length > 0 ? testuser(d) : true))
            );
        }
    }, [data, searchTerm]);

    useEffect(() => {
        if (error) {
            console.log('[responses] request error: ', error);
        }
    }, [error]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                height: '100%'
            }}
        >
            <Grid container xs={12}>
                <Grid item xs={8}>
                    <h2>ALL RESPONSES</h2>
                </Grid>
                <Grid container xs={4} justify="flex-end" flex>
                    {data && data.results && (
                        <TextField
                            color="#4D6078"
                            id="outlined-basic"
                            variant="outlined"
                            placeholder=" Search IDs"
                            onChange={e =>
                                setSearchTerm(
                                    e.target.value.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
                                )
                            }
                        />
                    )}
                </Grid>
            </Grid>
            <Snackbar
                open={error}
                autoHideDuration={8000}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                style={{ backgroundColor: 'red', width: '100%' }}
            >
                <h4>Error: {error}</h4>
            </Snackbar>
            {!data && !error && <CircularProgress />}
            {data && (
                <div style={{ height: '82vh' }}>
                    <DataGrid rows={rows} columns={columns} pageSize={50} />
                    <div id="tr-footer" />
                </div>
            )}
        </div>
    );
}
