import { auth } from './firebase';

export function isLoggedIn() {
    return auth.currentUser ? auth.currentUser.uid != null : false;
}

export async function logOut() {
    await auth.signOut();
}

export async function logIn(email, password) {
    await auth.signInWithEmailAndPassword(email, password);
}

export function getUserId() {
    return auth.currentUser ? auth.currentUser.uid : undefined;
}
